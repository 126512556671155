import { useSelector } from 'react-redux';
import { getIsHavePermissions } from '@bus/profile/selectors';
import WithoutPermissionRedirect from '@components/common/WithoutPermissionRedirect';
import React from 'react';
import { useSnackbar } from 'notistack';

const usePermissionCheck = () => {
  const isHavePermissions = useSelector(getIsHavePermissions);
  const { enqueueSnackbar } = useSnackbar();

  const notification = (message: string) => {
    if (!isHavePermissions) {
      enqueueSnackbar(message, {
        variant: 'infoSnackbar',
        action: () => <WithoutPermissionRedirect />,
      });

      return true;
    }

    return false;
  };

  return { notification };
};

export default usePermissionCheck;
