import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const History: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M14.1818 7.63634H12.5454V13.0909L17.2145 15.8618L18 14.5418L14.1818 12.2727V7.63634ZM13.6363 2.18182C11.0324 2.18182 8.53511 3.21623 6.69385 5.05749C4.85258 6.89875 3.81817 9.39603 3.81817 12H0.545441L4.86544 16.3963L9.27271 12H5.99999C5.99999 9.97468 6.80453 8.03235 8.23663 6.60026C9.66872 5.16817 11.6111 4.36363 13.6363 4.36363C15.6616 4.36363 17.604 5.16817 19.0361 6.60026C20.4682 8.03235 21.2727 9.97468 21.2727 12C21.2727 14.0252 20.4682 15.9676 19.0361 17.3997C17.604 18.8318 15.6616 19.6363 13.6363 19.6363C11.5309 19.6363 9.6218 18.7745 8.24726 17.389L6.69817 18.9381C7.60538 19.855 8.68617 20.5818 9.87743 21.0763C11.0687 21.5708 12.3465 21.823 13.6363 21.8181C16.2403 21.8181 18.7376 20.7837 20.5788 18.9424C22.4201 17.1012 23.4545 14.6039 23.4545 12C23.4545 9.39603 22.4201 6.89875 20.5788 5.05749C18.7376 3.21623 16.2403 2.18182 13.6363 2.18182Z" />
    </SvgIcon>
  );
};

export default History;
