import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Dialog, IconButton, Typography, useTheme } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { modalActions } from './state/actions';
import { getModalData } from './state/selectors';
import { styles, rootStyles } from './styles';
import Close from '@components/icons/Close';
import { getPersistModalData } from '@bus/persist/selectors';
import { persistActions } from '@bus/persist/actions';
import { projectsActions } from '@bus/projects/actions';
import parse from 'html-react-parser';

type ModalsProps = {
  registeredModals: ModalTypes.RegisteredModals;
};

export const Modals: React.FC<ModalsProps> = ({ registeredModals }) => {
  const modalData = useSelector(getModalData);
  const persistModalData = useSelector(getPersistModalData);
  const dispatch = useDispatch();
  const theme = useTheme();
  const data = Object.values(persistModalData).length
    ? Object.values(persistModalData)
    : Object.values(modalData);

  const close = (name: string) => {
    dispatch(projectsActions.clearVisualizePrompt());
    if (Object.values(persistModalData).length) {
      dispatch(persistActions.closeModal(name));
    } else {
      dispatch(modalActions.closeModal(name));
    }
  };

  const backgroundSelect = (variant?: string) => {
    switch (variant) {
      case 'top': {
        return theme.palette.common.surface['surface 1'];
      }
      case 'secondary': {
        return theme.palette.common.surface['surface 5'];
      }

      default: {
        return theme.palette.common.surface['surface 5'];
      }
    }
  };

  return (
    <>
      {data.map(
        ({ component, customStyles, paperStyles, ...rest }: any, index) => {
          const forceClose =
            rest.forceClose === undefined ? true : rest.forceClose;
          const title = rest.title === undefined ? false : rest.title;
          const { variant } = rest;
          const key = rest.id || component + index;
          const background = backgroundSelect(variant);
          const backdropVariant = `${variant}Backdrop`;

          return (
            <Dialog
              key={key}
              sx={() =>
                rootStyles.root(
                  customStyles ?? {},
                  paperStyles ?? {},
                  background,
                )
              }
              open={true}
              PaperProps={{
                sx: styles[variant as keyof typeof styles],
              }}
              slotProps={{
                backdrop: {
                  sx: styles[backdropVariant as keyof typeof styles],
                },
              }}
              disableEscapeKeyDown={!forceClose}
              onClose={() => forceClose && close(component)}>
              {forceClose && variant !== 'top' && (
                <Box sx={styles.titleSection} className={'titleSection'}>
                  <Typography variant={'body2'} color={'text.primary'}>
                    {title && parse(title)}
                  </Typography>
                  <IconButton
                    color={'success'}
                    onClick={() => close(component)}
                    aria-label={'close'}
                    sx={styles.button}>
                    <Close
                      fontSize={'small'}
                      htmlColor={theme.palette.text.secondary}
                    />
                  </IconButton>
                </Box>
              )}
              {React.createElement(registeredModals[component], {
                ...rest,
                closeFn: () => close(component),
              })}
            </Dialog>
          );
        },
      )}
    </>
  );
};
