import React, { FC, useCallback } from 'react';

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ModalProps,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';

import { styles } from './styles';
import Saved from '@components/icons/Saved';
import { book } from '@routes/book';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions } from '@bus/profile/actions';
import { ThemeVariant } from '@bus/ui/typedefs';
import { uiActions } from '@bus/ui/actions';
import { getTheme } from '@bus/ui/selectors';
import Bedtime from '@components/icons/Bedtime';
import UserProfile from '@components/icons/UserProfile';
import { getGuidance } from '@bus/guidance/selectors';
import { useIntercom } from 'react-use-intercom';
import Support from '@components/icons/Support';
import { modalActions } from '@core/Modal/state/actions';
import { createdImagesActions } from '@bus/createdImages/actions';
import { authActions } from '@bus/auth/actions';
import { optionsActions } from '@bus/options/actions';
import { projectsActions } from '@bus/projects/actions';
import ExitToApp from '@components/icons/ExitToApp';
import PaymentIcon from '@components/icons/PaymentIcon';
import GalleryLinear from '@components/icons/GalleryLinear';
import InfoCircleLinear from '@components/icons/InfoCircleLinear';
import AltArrowRightLine from '@components/icons/AltArrowRightLine';

type DrawerProfileContentProps = {
  onClose?: ModalProps['onClose'];
};

export const DrawerProfileContent: FC<DrawerProfileContentProps> = ({
  onClose,
}) => {
  const { startTour, show } = useIntercom();
  const theme = useTheme();
  const themes = useSelector(getTheme);
  const guides = useSelector(getGuidance);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickProfile = (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    onClose?.(event, 'backdropClick');
    navigate(book.profile);
  };

  const handleClickPayments = (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    onClose?.(event, 'backdropClick');
    navigate(book.payments);
  };

  const handleClickSaved = (event: React.KeyboardEvent | React.MouseEvent) => {
    onClose?.(event, 'backdropClick');
    navigate(`${book.savedMessages}/0`);
  };

  const handleClickGallery = (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    onClose?.(event, 'backdropClick');
    navigate(`${book.imageGallery}/0`);
  };

  const handleClickTutorial = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (themes === ThemeVariant.light) {
        dispatch(uiActions.fillTheme(ThemeVariant.dark));
      }
      if (guides) {
        onClose?.(event, 'backdropClick');
        startTour(guides.results[0].tour_id);
      }
    },
    [guides, themes],
  );

  const handleChange = () => {
    if (!themes) return;
    if (themes === ThemeVariant.dark) {
      dispatch(uiActions.fillTheme(ThemeVariant.light));
    } else {
      dispatch(uiActions.fillTheme(ThemeVariant.dark));
    }
  };

  const handleSupport = (event: React.KeyboardEvent | React.MouseEvent) => {
    onClose?.(event, 'backdropClick');
    show();
  };

  const handleLogout = (event: React.KeyboardEvent | React.MouseEvent) => {
    onClose?.(event, 'backdropClick');
    dispatch(
      modalActions.modal({
        component: 'ConfirmOrCancel',
        forceClose: false,
        onCancel: () => {
          dispatch(profileActions.clearData());
          dispatch(createdImagesActions.clearData());
          dispatch(authActions.isAuthenticatedFalse());
          dispatch(optionsActions.clearOptions());
          dispatch(projectsActions.clearProjects());
          sessionStorage.removeItem('redirectTo');
          navigate(book.signIn);
        },
        onConfirm: () => dispatch(modalActions.closeModal('ConfirmOrCancel')),
        modalPayload: {
          title: 'Confirm Action',
          body: 'Are you sure you want to Log Out?',
        },
        cancelButton: {
          text: 'Log Out',
        },
        confirmButton: {
          text: 'Go back',
        },
        paperStyles: {
          maxWidth: '472px',
          width: '100%',
        },
      }),
    );
  };

  return (
    <List sx={styles.appBarTooltip}>
      <ListItem disablePadding data-intercom-target={'Profile Settings'}>
        <ListItemButton onClick={handleClickProfile} sx={styles.button}>
          <Box sx={styles.wrapper}>
            <Box sx={styles.iconWrapper}>
              <UserProfile
                sx={{ fontSize: '20px' }}
                htmlColor={theme.palette.common.surface['surface 48']}
              />
            </Box>
            <Typography variant={'body1'} color={'text.primary'}>
              Profile Settings
            </Typography>
          </Box>
          <AltArrowRightLine
            fontSize={'small'}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding data-intercom-target={'Account'}>
        <ListItemButton onClick={handleClickPayments} sx={styles.button}>
          <Box sx={styles.wrapper}>
            <Box sx={styles.iconWrapper}>
              <PaymentIcon
                sx={{ fontSize: '20px' }}
                htmlColor={theme.palette.common.surface['surface 48']}
              />
            </Box>
            <Typography variant={'body1'} color={'text.primary'}>
              Account
            </Typography>
          </Box>
          <AltArrowRightLine
            fontSize={'small'}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding data-intercom-target={'Image Gallery'}>
        <ListItemButton onClick={handleClickGallery} sx={styles.button}>
          <Box sx={styles.wrapper}>
            <Box sx={styles.iconWrapper}>
              <GalleryLinear
                sx={{ fontSize: '20px' }}
                htmlColor={theme.palette.common.surface['surface 48']}
              />
            </Box>
            <Typography variant={'body1'} color={'text.primary'}>
              Image Gallery
            </Typography>
          </Box>
          <AltArrowRightLine
            fontSize={'small'}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding data-intercom-target={'Saved Messages'}>
        <ListItemButton onClick={handleClickSaved} sx={styles.button}>
          <Box sx={styles.wrapper}>
            <Box sx={styles.iconWrapper}>
              <Saved
                sx={{ fontSize: '20px' }}
                htmlColor={theme.palette.common.surface['surface 48']}
              />
            </Box>
            <Typography variant={'body1'} color={'text.primary'}>
              Saved Messages
            </Typography>
          </Box>
          <AltArrowRightLine
            fontSize={'small'}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <Box sx={styles.theme}>
          <Box sx={styles.iconWrapper}>
            <Bedtime
              sx={{ fontSize: '20px' }}
              htmlColor={theme.palette.common.surface['surface 48']}
            />
            <Typography variant={'body1'} color={'text.primary'} ml={'10px'}>
              Dark mode
            </Typography>
          </Box>
          <Switch
            sx={styles.switcher}
            checked={themes === ThemeVariant.dark}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Box>
      </ListItem>
      <ListItem disablePadding data-intercom-target={'Tutorial'}>
        <ListItemButton onClick={handleClickTutorial} sx={styles.button}>
          <Box sx={styles.wrapper}>
            <Box sx={styles.iconWrapper}>
              <InfoCircleLinear
                sx={{ fontSize: '20px' }}
                htmlColor={theme.palette.common.surface['surface 48']}
              />
            </Box>
            <Typography variant={'body1'} color={'text.primary'}>
              Tutorial
            </Typography>
          </Box>
          <AltArrowRightLine
            fontSize={'small'}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding data-intercom-target={'Support'}>
        <ListItemButton
          className={'show'}
          onClick={handleSupport}
          sx={styles.button}>
          <Box sx={styles.wrapper}>
            <Box sx={styles.iconWrapper}>
              <Support
                sx={{ fontSize: '20px' }}
                htmlColor={theme.palette.common.surface['surface 48']}
              />
            </Box>
            <Typography variant={'body1'} color={'text.primary'}>
              Support
            </Typography>
          </Box>
          <AltArrowRightLine
            fontSize={'small'}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding data-intercom-target={'Logout'}>
        <ListItemButton onClick={handleLogout} sx={styles.button}>
          <Box sx={styles.wrapper}>
            <Box sx={styles.iconWrapper}>
              <ExitToApp
                sx={{ fontSize: '20px' }}
                htmlColor={theme.palette.common.surface['surface 48']}
              />
            </Box>
            <Typography variant={'body1'} color={'text.primary'}>
              Logout
            </Typography>
          </Box>
          <AltArrowRightLine
            fontSize={'small'}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export default DrawerProfileContent;
