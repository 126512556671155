import React, { FC } from 'react';

import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import { handleErrors } from '@helpers/handleErrors';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsFetchingSummary,
  getSocialNetworks,
  getUploadedFaces,
} from '@bus/createdImages/selectors';
import Twitter from '@components/icons/Twitter';
import Linkedin from '@components/icons/Linkedin';
import Facebook from '@components/icons/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import RedditIcon from '@mui/icons-material/Reddit';
import { truncateText } from '@helpers/truncateText';
import { useSnackbar } from 'notistack';
import WrapFace from '@components/icons/WrapFace';
import { createdImagesActions } from '@bus/createdImages/actions';
import { profileActions } from '@bus/profile/actions';
import Regenerate from '@components/icons/Regenerate';
import Copy from '@components/icons/Copy';
import Download from '@components/icons/Download';
import { downloadImage } from '@helpers/downloadImage';
import { modalActions } from '@core/Modal/state/actions';
import usePermissionCheck from '../../../../../hooks/usePermissionCheck';

type ShareProps = {
  summary: string;
  shortImage: string;
  image: string;
  type: string;
  id?: number | string;
};

export const Share: FC<ShareProps> = ({
  summary,
  shortImage,
  image,
  type,
  id,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isFetching = useSelector(getIsFetchingSummary);
  const networks = useSelector(getSocialNetworks);
  const uploadedFaces = useSelector(getUploadedFaces);
  const maxChars: { [key: string]: number } = {
    twitter: 280,
    reddit: 300,
    linkedin: 1300,
    facebook: 63206,
    telegram: 4096,
    whatsApp: 4096,
  };
  const encodedUrl = encodeURIComponent(image);
  const iconMap: { [key: string]: React.JSX.Element } = {
    Twitter: <Twitter fontSize={'small'} />,
    Linkedin: <Linkedin fontSize={'small'} />,
    Facebook: <Facebook fontSize={'small'} />,
    Telegram: <TelegramIcon fontSize={'small'} />,
    WhatsApp: <WhatsAppIcon fontSize={'small'} />,
    Reddit: <RedditIcon fontSize={'small'} />,
  };
  const handleClickShare = (event: any) => {
    event.stopPropagation();
  };

  const { enqueueSnackbar } = useSnackbar();
  const { notification } = usePermissionCheck();

  const handleCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigator.clipboard
      .writeText(`${image}\n\n${summary}`)
      .then()
      .catch((error) => {
        handleErrors(error);
      });
    enqueueSnackbar('The text was copied', { variant: 'infoSnackbar' });
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    social: string,
  ) => {
    event.stopPropagation();
    const popupWidth = 600;
    const popupHeight = 400;
    const left = window.innerWidth / 2 - popupWidth / 2;
    const top = window.innerHeight / 2 - popupHeight / 2;
    const popupParams = `scrollbars=no, width=${popupWidth}, height=${popupHeight}, top=${top}, left=${left}`;
    let shareUrl = '';

    const socialNetwork = networks.find((item) => item.name === social);
    const truncatedValue = truncateText(
      `${summary}`,
      maxChars[social] || 500,
      false,
      socialNetwork?.tags.join(' ') ?? '',
      shortImage,
    );
    const truncatedValueWithUrl = truncateText(
      `${shortImage ?? ''}\n\n${summary}`,
      maxChars[social] || 500,
      true,
      socialNetwork?.tags.join(' ') ?? '',
      shortImage,
    );
    const encodedValueWithUrl = encodeURIComponent(truncatedValueWithUrl);
    const encodedValue = encodeURIComponent(truncatedValue);

    switch (social) {
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?text=${encodedValueWithUrl}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}&text=${encodedValue}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        break;
      case 'telegram':
        shareUrl = `https://t.me/share/url?url=${encodedUrl}&text=${encodedValue}`;
        break;
      case 'whatsApp':
        shareUrl = `https://api.whatsapp.com/send?text=${encodedValue} ${encodedUrl}`;
        break;
      case 'reddit':
        shareUrl = `https://www.reddit.com/submit?url=${encodedUrl}&title=${encodedValue}`;
        break;
      default:
        break;
    }

    window.open(shareUrl, 'sharePopup', popupParams);
  };

  const handleOpenSwap = () => {
    dispatch(profileActions.setScaleImage(null));
    if (notification(`Can't generate new image`)) {
      return;
    }

    dispatch(
      createdImagesActions.createSwap({
        target_image_url: image,
        swap_image_url: uploadedFaces[0].image,
      }),
    );
    dispatch(
      modalActions.modal({
        component: 'ImageWrapStudio',
        forceClose: false,
        variant: 'secondary',
      }),
    );
  };

  const handleRegenerate = () => {
    dispatch(profileActions.setScaleImage(null));
    if (notification(`Can't regenerate image`)) {
      return;
    }

    new Promise((resolve, reject) => {
      dispatch(
        createdImagesActions.regenerateGeneratedImage({
          values: { id },
          resolve,
          reject,
        }),
      );
    })
      .then()
      .catch();
  };

  return (
    <Box sx={styles.share} onClick={handleClickShare}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.leftWrapper}>
          <Typography variant={'body1'} color={'text.secondary'}>
            Share image
          </Typography>
          {networks.map((item) => (
            <IconButton
              id={item.id}
              key={item.id}
              sx={styles.button}
              onClick={(e) => handleClick(e, item.name)}
              color={'warning'}
              disabled={isFetching}>
              {iconMap[item.icon]}
            </IconButton>
          ))}
        </Box>

        <Box sx={styles.rightWrapper}>
          <Button
            variant={'drawer'}
            color={'secondary'}
            startIcon={
              <WrapFace
                fontSize={'small'}
                htmlColor={theme.palette.common.surface['surface 46']}
              />
            }
            onClick={handleOpenSwap}
            disabled={isFetching}>
            <Typography variant={'body1'} color={'text.secondary'}>
              Face Swap
            </Typography>
          </Button>
          {type === 'studio' && (
            <Button
              variant={'drawer'}
              color={'secondary'}
              startIcon={
                <Regenerate
                  fontSize={'small'}
                  htmlColor={theme.palette.common.surface['surface 46']}
                />
              }
              onClick={handleRegenerate}
              disabled={isFetching}>
              <Typography variant={'body1'} color={'text.secondary'}>
                Regenerate Image
              </Typography>
            </Button>
          )}
          <Button
            variant={'drawer'}
            color={'secondary'}
            startIcon={
              <Copy
                fontSize={'small'}
                htmlColor={theme.palette.common.surface['surface 46']}
              />
            }
            onClick={handleCopy}
            disabled={isFetching}>
            <Typography variant={'body1'} color={'text.secondary'}>
              Copy summary
            </Typography>
          </Button>
          <Button
            variant={'drawer'}
            color={'secondary'}
            startIcon={
              <Download
                fontSize={'small'}
                htmlColor={theme.palette.common.surface['surface 46']}
              />
            }
            onClick={() => downloadImage(image)}>
            <Typography variant={'body1'} color={'text.secondary'}>
              Download
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Share;
