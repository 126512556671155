import React from 'react';

import { Box, Typography } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import { IProjects } from '@bus/projects/typedefs';
import DatasetItem from '@components/pages/private/HomePage/DatasetItem';
import TimeLeftToConversation from '@components/common/TimeLeftToConversation';
import { useDispatch, useSelector } from 'react-redux';
import { getNextSession } from '@bus/aiConversation/selectors';
import MasterMenuEnnealogy from '@assets/MasterMenuEnnealogy.png';
import MasterMenuSibyl from '@assets/MasterMenuSibyl.png';
import MasterMenuMetametrics from '@assets/MasterMenuMetametrics.png';
import MasterMenuImageGeneration from '@assets/MasterMenuImageGeneration.png';
import PopularDatasetItem from '@components/common/PopularDatasetItem';
import FeatureMetametrics from '@components/icons/FeatureMetametrics';
import FeatureImageGeneration from '@components/icons/FeatureImageGeneration';
import FeatureFaceSwap from '@components/icons/FeatureFaceSwap';
import FeatureImageGallery from '@components/icons/FeatureImageGallery';
import FeatureStoryteller from '@components/icons/FeatureStoryteller';
import { useNavigate } from 'react-router-dom';
import {
  getCanDoVisualizeAction,
  getShowVisualizeBuyTokensModal,
  getShowVisualizeUpdatePlanToUseTokensModal,
} from '@bus/profile/selectors';
import { book } from '@routes/book';
import { profileActions } from '@bus/profile/actions';
import { createdImagesActions } from '@bus/createdImages/actions';
import { modalActions } from '@core/Modal/state/actions';
import { getUploadedFaces } from '@bus/createdImages/selectors';
import { useLimits } from '../../../hooks/useLimits';
import {
  getPopularNotProjects,
  getPopularProjects,
} from '@bus/projects/selectors';
import usePermissionCheck from '../../../hooks/usePermissionCheck';

type MasterMenuProps = {};

export const MasterMenu: React.FC<
  ModalTypes.ModalComponentProps<MasterMenuProps>
> = ({ closeFn }) => {
  const nextSession = useSelector(getNextSession);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uploadedFaces = useSelector(getUploadedFaces);
  const popularProjects = useSelector(getPopularProjects);
  const notPopularProjects = useSelector(getPopularNotProjects);
  const { notification } = usePermissionCheck();

  const canDoAction = useSelector(getCanDoVisualizeAction);
  const showBuyTokensModal = useSelector(getShowVisualizeBuyTokensModal);
  const showUpdatePlanToUseTokensModal = useSelector(
    getShowVisualizeUpdatePlanToUseTokensModal,
  );

  const { handleLimitExceeded } = useLimits(
    showUpdatePlanToUseTokensModal,
    showBuyTokensModal,
    'text_line_2',
  );

  const closeModal = () => {
    closeFn();
  };
  const handleOpenStudio = () => {
    closeModal();

    if (notification(`Can't generate new image`)) {
      return;
    }

    if (!canDoAction) {
      handleLimitExceeded();

      return;
    }

    dispatch(
      modalActions.modal({
        component: 'ImageGenerationStudio',
        forceClose: false,
        variant: 'secondary',
      }),
    );
  };
  const handleOpenWrapFace = () => {
    closeModal();

    if (notification(`Can't generate new image`)) {
      return;
    }

    dispatch(
      createdImagesActions.createSwap({
        swap_image_url: uploadedFaces[0]?.image,
      }),
    );
    dispatch(
      modalActions.modal({
        component: 'ImageWrapStudio',
        forceClose: false,
        variant: 'secondary',
      }),
    );
  };
  const handleChatNavigate = (project: IProjects) => {
    closeModal();

    dispatch(profileActions.setCurrentDataset(project));
    closeModal();
    if (project.chats.length) {
      navigate(`${book.chat}/${project.name}/${project.chats[0].id}`);
    } else {
      navigate(`${book.home}`);
    }
  };
  const imageGalleryNavigate = () => {
    closeModal();
    navigate(`${book.imageGallery}/0`);
  };
  const storytellerNavigate = () => {
    closeModal();
    navigate(book.storyTellerHome);
  };
  const metametricsNavigate = () => {
    closeModal();
    navigate(book.metametrics);
  };

  const selectImage = (id: number | string) => {
    if (id === 1) {
      return MasterMenuEnnealogy;
    }
    if (id === 19) {
      return MasterMenuSibyl;
    }
    if (id === 'metametrics') {
      return MasterMenuMetametrics;
    }
    if (id === 'image generation') {
      return MasterMenuImageGeneration;
    }

    return null;
  };

  return (
    <Box sx={styles.masterMenu}>
      <Box sx={styles.dataSetWrapper}>
        <Box sx={styles.datasetTitle}>
          <Typography variant={'body2'} color={'text.primary'}>
            Generative Models
          </Typography>
        </Box>
        <Box sx={styles.projectsWrapper}>
          <Box sx={styles.popularProjectsWrapper}>
            {popularProjects.map((item) => (
              <PopularDatasetItem
                key={item.id}
                name={item.name}
                description={item.description}
                image={selectImage(item.id)}
                icon={item.icon}
                action={() => handleChatNavigate(item)}
              />
            ))}
          </Box>
          <Box sx={styles.notPopularProjectsWrapper}>
            {notPopularProjects.map((item) => (
              <DatasetItem
                key={item.id}
                name={item.name}
                description={item.description}
                icon={item.icon}
                action={() => handleChatNavigate(item)}
              />
            ))}
          </Box>
        </Box>
      </Box>

      <Box sx={styles.dataSetWrapper}>
        <Box sx={styles.datasetTitle}>
          <Typography variant={'body2'} color={'text.primary'}>
            Features
          </Typography>
        </Box>
        <Box sx={styles.projectsWrapper}>
          <Box sx={styles.popularProjectsWrapper}>
            <PopularDatasetItem
              name={'Metametrics'}
              description={`Advanced personal prediction and total self understanding.`}
              image={selectImage('metametrics')}
              icon={FeatureMetametrics}
              action={metametricsNavigate}
            />
            <Box sx={styles.imageGeneration}>
              <PopularDatasetItem
                name={'Image Generation'}
                description={`Create stunning imagery with text for your imagination.`}
                image={selectImage('image generation')}
                icon={FeatureImageGeneration}
                action={handleOpenStudio}
              />
            </Box>
          </Box>
          <Box sx={styles.notPopularProjectsWrapper}>
            <Box sx={styles.imageGenerationMobile}>
              <DatasetItem
                name={'Image Generation'}
                description={`Create stunning imagery with text for your imagination.`}
                icon={FeatureImageGeneration}
                action={handleOpenStudio}
              />
            </Box>
            <DatasetItem
              name={'Image Gallery'}
              description={`Review, download, and share all your imagery to social media.`}
              icon={FeatureImageGallery}
              action={imageGalleryNavigate}
            />
            <DatasetItem
              name={'Face Swap'}
              description={`Project your face on to any image for realistic results.`}
              icon={FeatureFaceSwap}
              action={handleOpenWrapFace}
            />
            <DatasetItem
              name={'Storyteller'}
              description={`Illustrate your short stories with incredible imagery.`}
              icon={FeatureStoryteller}
              action={storytellerNavigate}
            />
            <TimeLeftToConversation
              targetDate={nextSession?.start_at ?? ''}
              closeModal={closeModal}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MasterMenu;
