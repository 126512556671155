import React, { FC } from 'react';

import { Box, Button, ModalProps, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import DrawerHeader from '@components/common/DrawerHeader';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentDataset } from '@bus/profile/selectors';
import ChatOutline from '@components/icons/ChatOutline';
import SeenCircleOutline from '@components/icons/SeenCircleOutline';
import AddCircleOutline from '@components/icons/AddCircleOutline';
import { projectsActions } from '@bus/projects/actions';
import { IGuidance } from '@bus/projects/typedefs';
import { useParams } from 'react-router-dom';
import { getProjectId } from '@bus/projects/selectors';

type ChatGuidanceDrawerProps = {
  onClose?: ModalProps['onClose'];
};

export const ChatGuidanceDrawer: FC<ChatGuidanceDrawerProps> = ({
  onClose,
}) => {
  const theme = useTheme();
  const { projectName } = useParams();
  const dispatch = useDispatch();
  const projectId = useSelector(getProjectId(projectName));
  const currentDataset = useSelector(getCurrentDataset);

  const handleClick = (
    event: React.KeyboardEvent | React.MouseEvent,
    guide: IGuidance,
  ) => {
    !guide.is_read &&
      dispatch(
        projectsActions.readGuidance({
          guidance_id: guide.id,
          subguidance_id: null,
          projectId,
        }),
      );
    dispatch(projectsActions.fillGuidanceQuestion(guide.text));
    onClose?.(event, 'backdropClick');
  };

  return (
    <Box sx={styles.chatHistoryDrawer}>
      <DrawerHeader
        onClose={onClose}
        name={'Guidance'}
        icon={
          <ChatOutline
            sx={{ fontSize: '22px' }}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
        }
      />
      {currentDataset?.guidances && (
        <Box sx={styles.list}>
          {currentDataset.guidances.map((guide) => (
            <Button
              variant={'drawer'}
              key={guide.id}
              sx={styles.button}
              startIcon={
                guide.is_read ? (
                  <SeenCircleOutline
                    sx={{ fontSize: '16px' }}
                    htmlColor={theme.palette.common.surface['surface 47']}
                  />
                ) : (
                  <AddCircleOutline
                    sx={{ fontSize: '16px' }}
                    htmlColor={theme.palette.common.surface['surface 47']}
                  />
                )
              }
              onClick={(event) => handleClick(event, guide)}>
              <Typography variant={'body1'} color={'text.primary'}>
                {guide.title}
              </Typography>
            </Button>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ChatGuidanceDrawer;
