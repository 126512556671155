import React, { FC } from 'react';

import { Box, ModalProps, useTheme } from '@mui/material';

import { styles } from './styles';
import DrawerHeader from '@components/common/DrawerHeader';
import User from '@components/icons/User';
import DrawerProfileContent from '@components/common/DrawerProfileContent';

type ProfileDrawerProps = {
  onClose?: ModalProps['onClose'];
};

export const ProfileDrawer: FC<ProfileDrawerProps> = ({ onClose }) => {
  const theme = useTheme();

  return (
    <Box sx={styles.profileDrawer}>
      <DrawerHeader
        onClose={onClose}
        name={'Profile'}
        isBorder={false}
        icon={
          <User
            sx={{ fontSize: '22px' }}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
        }
      />
      <DrawerProfileContent onClose={onClose} />
    </Box>
  );
};

export default ProfileDrawer;
