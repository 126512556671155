import React from 'react';

import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  Typography,
  TypographyTypeMap,
} from '@mui/material';
import CheckboxDefault from '@components/icons/CheckboxDefault';
import CheckboxChecked from '@components/icons/CheckboxChecked';
import { styles } from '@components/common/inputs/Checkbox/styles';

type CheckboxProps = {
  checked?: boolean;
  color?: string;
  onChange?: (e: boolean) => void;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  helperText?: string;
  labelVariant?: TypographyTypeMap['props']['variant'];
  size: number;
  htmlColor?: string;
};

export const Checkbox: React.FC<CheckboxProps> = ({
  onChange,
  label,
  error,
  helperText,
  labelVariant = 'body1',
  checked,
  color = 'primary',
  disabled = false,
  size,
  htmlColor,
  ...props
}) => {
  return (
    <FormControlLabel
      {...props}
      checked={checked}
      sx={[error ? styles.error : styles.label]}
      control={
        <MuiCheckbox
          className={'checkbox'}
          disabled={disabled}
          icon={
            <CheckboxDefault
              sx={{ fontSize: `${size}px` }}
              htmlColor={htmlColor}
            />
          }
          checkedIcon={
            <CheckboxChecked
              sx={{ fontSize: `${size}px` }}
              htmlColor={htmlColor}
            />
          }
          onClick={() => {
            onChange?.(!checked);
          }}
        />
      }
      label={
        label && (
          <Typography
            ml={'10px'}
            variant={labelVariant}
            color={error ? 'error' : color}>
            {label}
          </Typography>
        )
      }
    />
  );
};
