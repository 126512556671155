import React, { FC } from 'react';

import { Box, Button, ModalProps, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import DrawerHeader from '@components/common/DrawerHeader';
import Plus from '@components/icons/Plus';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCanDoVisualizeAction,
  getShowVisualizeBuyTokensModal,
  getShowVisualizeUpdatePlanToUseTokensModal,
} from '@bus/profile/selectors';
import { book } from '@routes/book';
import { useNavigate, useParams } from 'react-router-dom';
import { modalActions } from '@core/Modal/state/actions';
import Filter from '@components/icons/Filter';
import { createdImagesActions } from '@bus/createdImages/actions';
import { getFilters, getUploadedFaces } from '@bus/createdImages/selectors';
import Done from '@components/icons/Done';
import { useLimits } from '../../../hooks/useLimits';
import usePermissionCheck from '../../../hooks/usePermissionCheck';

type GalleryDrawerProps = {
  onClose?: ModalProps['onClose'];
};

export const GalleryDrawer: FC<GalleryDrawerProps> = ({ onClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uploadedFaces = useSelector(getUploadedFaces);
  const filters = useSelector(getFilters);
  const { imageId } = useParams();
  const { notification } = usePermissionCheck();

  const canDoAction = useSelector(getCanDoVisualizeAction);
  const showBuyTokensModal = useSelector(getShowVisualizeBuyTokensModal);
  const showUpdatePlanToUseTokensModal = useSelector(
    getShowVisualizeUpdatePlanToUseTokensModal,
  );

  const { handleLimitExceeded } = useLimits(
    showUpdatePlanToUseTokensModal,
    showBuyTokensModal,
    'text_line_2',
  );

  const handleClick = (
    id: number | string,
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    navigate(`${book.imageGallery}/${id}`);
    onClose?.(event, 'backdropClick');
  };

  const handleOpenStudio = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClose?.(event, 'backdropClick');

    if (notification(`Can't generate new image`)) {
      return;
    }

    if (!canDoAction) {
      handleLimitExceeded();

      return;
    }

    event.stopPropagation();
    dispatch(
      modalActions.modal({
        component: 'ImageGenerationStudio',
        forceClose: false,
        variant: 'secondary',
      }),
    );
  };

  const handleOpenWrapFace = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClose?.(event, 'backdropClick');

    if (notification(`Can't generate new image`)) {
      return;
    }

    dispatch(
      createdImagesActions.createSwap({
        swap_image_url: uploadedFaces[0].image,
      }),
    );
    event.stopPropagation();
    dispatch(
      modalActions.modal({
        component: 'ImageWrapStudio',
        forceClose: false,
        variant: 'secondary',
      }),
    );
  };

  return (
    <Box sx={styles.galleryDrawer}>
      <DrawerHeader
        onClose={onClose}
        name={'Filter'}
        icon={
          <Filter
            sx={{ fontSize: '22px' }}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
        }
      />
      <Button
        variant={'drawerCreateChat'}
        startIcon={
          <Plus
            sx={{ fontSize: '20px' }}
            htmlColor={theme.palette.common.surface['surface 46']}
          />
        }
        onClick={handleOpenStudio}>
        <Typography variant={'body1'} color={'common.brand'}>
          Generate new image
        </Typography>
      </Button>
      <Button
        variant={'drawerCreateChat'}
        startIcon={
          <Plus
            sx={{ fontSize: '20px' }}
            htmlColor={theme.palette.common.surface['surface 46']}
          />
        }
        onClick={handleOpenWrapFace}>
        <Typography variant={'body1'} color={'common.brand'}>
          Generate Face Swap
        </Typography>
      </Button>
      <Box sx={styles.list}>
        {filters.map((item) => (
          <Button
            key={item.id}
            variant={'drawerFilter'}
            disabled={imageId === item.id.toString()}
            onClick={(event) => handleClick(item.id, event)}>
            <Box display={'flex'} alignItems={'center'} gap={'16px'}>
              {item.icon &&
                React.createElement(item.icon, {
                  fontSize: 'small',
                  htmlColor: theme.palette.common.surface['surface 46'],
                })}
              <Typography variant={'body1'}>{item.name}</Typography>
            </Box>
            {imageId === item.id.toString() && (
              <Done
                sx={{ fontSize: '16px' }}
                htmlColor={theme.palette.text.primary}
              />
            )}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default GalleryDrawer;
