import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, Typography, useTheme } from '@mui/material';

import { Checkbox } from '@components/common/inputs/Checkbox';

export const RenderMagicField: React.FC<FieldRenderProps<string>> = ({
  input,
}) => {
  const theme = useTheme();

  return (
    <Box display={'flex'} alignItems={'center'} gap={'10px'} m={'20px 0 0'}>
      <Checkbox
        {...input}
        data-testid={`visual-adjustments-form-magic`}
        checked={!input.checked}
        onChange={() => input.onChange(!input.checked)}
        size={14}
        htmlColor={theme.palette.common.brand}
      />
      <Typography variant={'body1'} color={'text.secondary'}>
        {"I want to see magic, don't show the adjustments again"}
      </Typography>
    </Box>
  );
};
