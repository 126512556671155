import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckboxChecked: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M21 0H3C1.35 0 0 1.35 0 3V21C0 22.65 1.35 24 3 24H21C22.65 24 24 22.65 24 21V3C24 1.35 22.65 0 21 0ZM21 21H3V3H21V21Z" />
      <rect x="9" y="9" width="6" height="6" />
    </SvgIcon>
  );
};

export default CheckboxChecked;
