import React, { FC } from 'react';

import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';

import { book } from '@routes/book';

type WithoutPermissionRedirectProps = {};

export const WithoutPermissionRedirect: FC<
  WithoutPermissionRedirectProps
> = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const handleNavigate = () => {
    window.location.href = book.payments;
  };

  return (
    <Button variant={'underline'} onClick={handleNavigate}>
      <Typography variant={'body1'}>
        {isMd ? 'View' : 'Renew your subscription'}
      </Typography>
    </Button>
  );
};

export default WithoutPermissionRedirect;
