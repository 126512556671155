import React, { FC } from 'react';

import { Box, ModalProps, useTheme } from '@mui/material';

import { styles } from './styles';
import DrawerHeader from '@components/common/DrawerHeader';
import History from '@components/icons/History';
import { useSelector } from 'react-redux';
import { book } from '@routes/book';
import { useNavigate } from 'react-router-dom';
import { getSessions } from '@bus/aiConversation/selectors';
import AIHistory from '@components/pages/common/ConversationPage/AIHistory';

type ConversationHistoryDrawerProps = {
  onClose?: ModalProps['onClose'];
};

export const ConversationHistoryDrawer: FC<ConversationHistoryDrawerProps> = ({
  onClose,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const sessions = useSelector(getSessions);

  const handleClick = (
    sessionId: number | string,
    month: string,
    year: number,
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    navigate(`${book.conversation}/${year}/${month}/${sessionId}`);
    onClose?.(event, 'backdropClick');
  };

  return (
    <Box sx={styles.chatHistoryDrawer}>
      <DrawerHeader
        onClose={onClose}
        name={'History'}
        icon={
          <History
            sx={{ fontSize: '22px' }}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
        }
        isBorder={false}
      />
      <AIHistory list={sessions} handleClick={handleClick} />
    </Box>
  );
};

export default ConversationHistoryDrawer;
