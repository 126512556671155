import { createAction } from '@reduxjs/toolkit';

import metametricsSlice from './slice';
import prepareActions from '@helpers/prepareActions';

export const metametricsActions = {
  ...metametricsSlice.actions,
  fetchMetametrics: createAction('metametrics/fetchMetametrics'),
  fetchMetametricsArchive: createAction('metametrics/fetchMetametricsArchive'),
  deleteMetametrics: createAction(
    'metametrics/deleteMetametrics',
    prepareActions.movePromiseToMeta,
  ),
  updateMetametrucsStatus: createAction(
    'metametrics/updateMetametrucsStatus',
    prepareActions.movePromiseToMeta,
  ),
  restoreMetametricsStatus: createAction(
    'metametrics/restoreMetametricsStatus',
    prepareActions.movePromiseToMeta,
  ),
  fetchMetametricsCard: createAction(
    'metametrics/fetchMetametricsCard',
    ({ id, isTeaser }) => ({
      payload: { id, isTeaser },
    }),
  ),
  createChat: createAction(
    'metametrics/createChat',
    prepareActions.withPromiseNavigate,
  ),
  // INJECT
};
