import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Search: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M10.9954 2.18182C6.13836 2.18182 2.18182 6.137 2.18182 10.9924C2.18182 15.8479 6.13836 19.8107 10.9954 19.8107C13.07 19.8107 14.978 19.0839 16.486 17.8772L20.1572 21.5453C20.3425 21.7228 20.5899 21.8208 20.8466 21.8181C21.1032 21.8155 21.3486 21.7125 21.5302 21.5313C21.7118 21.35 21.8151 21.1049 21.8181 20.8483C21.8211 20.5918 21.7235 20.3443 21.5461 20.1588L17.8749 16.4889C19.083 14.9791 19.811 13.0687 19.811 10.9924C19.811 6.137 15.8525 2.18182 10.9954 2.18182ZM10.9954 4.14019C14.7938 4.14019 17.85 7.19539 17.85 10.9924C17.85 14.7895 14.7938 17.8524 10.9954 17.8524C7.19708 17.8524 4.14083 14.7895 4.14083 10.9924C4.14083 7.19539 7.19708 4.14019 10.9954 4.14019Z" />
    </SvgIcon>
  );
};

export default Search;
