import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Close: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M20.7273 5.03065L18.9694 3.27273L12 10.2421L5.03066 3.27273L3.27274 5.03065L10.2421 12L3.27274 18.9694L5.03066 20.7273L12 13.7579L18.9694 20.7273L20.7273 18.9694L13.7579 12L20.7273 5.03065Z" />
    </SvgIcon>
  );
};

export default Close;
